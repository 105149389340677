<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-1">
          <v-card-title>
            <h2>Wijzig jouw profiel</h2>
          </v-card-title>
          <v-card-text>
            <v-form v-model="profile.valid" ref="profileForm" @submit.prevent="updateProfile">
              <v-text-field
                v-model="profile.name"
                :rules="profile.nameRules"
                label="Naam"
                required
              ></v-text-field>
              <v-text-field
                v-model="profile.email"
                :rules="profile.emailRules"
                label="Email"
                required
              ></v-text-field>
              <v-text-field
                v-model="profile.phoneNumber"
                :rules="profile.phoneNumberRules"
                label="Telefoonnummer"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" :disabled="!profile.valid">
                Bewaar wijzigingen
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="elevation-1">
          <v-card-title>
            <h2>Wijzig jouw wachtwoord</h2>
          </v-card-title>
          <v-card-text>
            <v-form v-model="password.valid" ref="passwordForm" @submit.prevent="updatePassword">
              <input type="text" name="username" autocomplete="username email"
                     :value="profile.email"
                     style="display: none;">
              <v-text-field
                type="password"
                autocomplete="current-password"
                v-model="password.currentPassword"
                :rules="password.passwordRules"
                label="Huidig wachtwoord"
                required
              ></v-text-field>
              <v-text-field
                type="password"
                autocomplete="new-password"
                v-model="password.newPassword"
                :rules="password.passwordRules"
                label="Nieuw wachtwoord"
                required
              ></v-text-field>
              <v-text-field
                type="password"
                autocomplete="new-password"
                v-model="password.confirmedPassword"
                :rules="password.passwordRules"
                label="Bevestig nieuw wachtwoord"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary" :disabled="!password.valid">
                Bewaar wijzigingen
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    profile: {
      valid: false,
      nameRules: [(v) => !!v || 'Naam is verplicht'],
      emailRules: [
        (v) => !!v || 'Email is verplicht',
        (v) => /.+@.+/.test(v) || 'Ongeldige email adres',
      ],
      phoneNumberRules: [
        (v) => !!v || 'Telefoonnummer is verplicht',
        (v) => v.length >= 10 || 'Telefoonnummer is ongeldig',
      ],
      name: null,
      email: null,
      phoneNumber: null,
    },
    password: {
      valid: false,
      passwordRules: [
        (v) => !!v || 'Wachtwoord is verplicht',
        (v) => v.length >= 5 || 'Wachtwoord is te kort',
      ],
      currentPassword: '',
      newPassword: '',
      confirmedPassword: '',
    },
  }),
  created () {
    let user = this.$store.getters['globalStore/user']
    this.profile.name = user.name
    this.profile.email = user.email
    this.profile.phoneNumber = user.phoneNumber
  },
  methods: {
    updateProfile () {
      if (!this.profile.valid) return false

      let user = this.$store.getters['globalStore/user']
      user.name = this.profile.name
      user.email = this.profile.email
      user.phoneNumber = this.profile.phoneNumber

      this.$store.dispatch('globalStore/updateProfile', user)
        .then(() => {
          this.$store.dispatch('globalStore/addFlashMessage', {
            type: 'success',
            message: 'Jouw profiel is bijgewerkt',
          })
        })
    },
    updatePassword () {
      if (!this.password.valid) return false
      let user = this.$store.getters['globalStore/user']
      let passwordRequest = {
        id: user.id,
        currentPassword: this.password.currentPassword,
        newPassword: this.password.newPassword,
        confirmPassword: this.password.confirmedPassword,
      }
      this.$store
        .dispatch('globalStore/updatePassword', passwordRequest)
        .then(() => {
          this.$store.dispatch('globalStore/addFlashMessage', {
            type: 'success',
            message: 'Jouw wachtwoord is gewijzigd',
          })
          // reset password form
          this.$refs.passwordForm.reset()
          this.password.currentPassword = ''
          this.password.newPassword = ''
          this.password.confirmedPassword = ''
        })
    },
  },
}
</script>
